<template>
  <div>
    <Desktop v-if="!isMobile" />
    <Mobile v-if="isMobile" />
  </div>
</template>
 
<script>
import Desktop from "@/components/support-materials/views/Desktop.vue";
import Mobile from "@/components/support-materials/views/Mobile.vue";
export default {
  components: {
    Desktop,
    Mobile,
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>

<style>
</style>