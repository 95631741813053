<template>
  <div>
    <v-dialog v-model="dialog" width="350px">
      <v-card>
        <v-card-title> Etiqueta </v-card-title>
        <v-card-text>
          <v-row class="mt-2 mb-3">
            <v-col>
              <app-text-field
                v-model="tag.name"
                placeholder="Nome"
                solo
                flat
                background-color="grey lighten-4"
              />
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <template v-for="(color, index) of colors">
              <v-col class="text-center" cols="3" :key="index">
                <v-btn
                  small
                  :color="color"
                  fab
                  dark
                  elevation="0"
                  @click="tag.color = color"
                >
                  <v-icon
                    class="material-symbols-rounded"
                    v-if="tag.color == color"
                    >done</v-icon
                  >
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="!isInsert"
            @click="handleDeleteTag()"
            rounded
            color="error"
            outlined
          >
            Excluir
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" rounded outlined> Voltar </v-btn>
          <v-btn @click="handleSave()" rounded color="primary">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    type: {},
  },

  data: () => ({
    dialog: false,

    tag: {},

    baseTag: {
      id: null,
      name: null,
      type: null,
      color: "#7955F6",
    },

    colors: [
      "#7955F6",
      "#3AC9F9",
      "#F6DF5E",
      "#11D69F",
      "#EE3862",
      "#F77F52",
      "#653E70",
      "#6B788E",
    ],
  }),

  computed: {
    isInsert() {
      return this.tag.id == null;
    },
  },

  methods: {
    open(tag) {
      this.reset();

      if (tag) {
        this.tag = tag;
      }

      this.dialog = true;
    },

    reset() {
      this.tag = JSON.parse(JSON.stringify(this.baseTag));
      this.tag.type = this.type;
    },

    handleSave() {
      if (this.isInsert) {
        this.store();
      } else {
        this.update();
      }
    },

    store() {
      this.$http.store("tag/tag", this.tag).then((response) => {
        this.processSaved();
      });
    },

    update() {
      this.$http.update("tag/tag", this.tag.id, this.tag).then((response) => {
        this.processSaved();
      });
    },

    processSaved() {
      this.$emit("store");
      this.dialog = false;
    },

    handleDeleteTag() {
      this.$http.destroy("tag/tag", this.tag.id).then((response) => {
        this.$emit("delete", this.tag.id);
        this.processSaved();
      });
    },
  },
};
</script>

<style>
</style>