<template>
  <div>
    <v-alert dense type="info">
      <v-row align="center">
        <v-col cols="12" md="10">
          Divulgue seu trabalho no EncontrePsi e seja visto por milhares de
          pessoas em busca de terapia
        </v-col>
        <v-col class="text-right">
          <v-btn @click="openLink()" elevation="0" color="info">
            Saiba mais!
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <v-card outlined>
      <v-card-title
        class="d-flex justify-space-between align-center"
        style="padding-bottom: 5px !important"
      >
        <app-title>{{ $route.meta.name }}</app-title>
        <div v-if="$admin.isAdmin()">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">
                Adicionar
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="handleMaterialForm()">
                <v-list-item-title>Material</v-list-item-title>
              </v-list-item>
              <v-list-item @click="handleTabForm()">
                <v-list-item-title>Aba</v-list-item-title>
              </v-list-item>
              <v-list-item @click="handleFolderForm()">
                <v-list-item-title>Pasta</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-card-title>
      <v-card-text class="px-2">
        <div class="ml-4 pb-1">
          <v-tabs>
            <template v-for="item in tabs">
              <v-tab :key="item.id" @click="selectTab(item.id)">
                <v-icon
                  v-if="$admin.isAdmin()"
                  @click.stop="handleTabForm(item.id)"
                  left
                >
                  mdi-pencil
                </v-icon>
                {{ item.name }}
              </v-tab>
            </template>
          </v-tabs>
        </div>
        <v-row no-gutters>
          <template v-for="(item, index) in folders">
            <v-col cols="2" class="flex-wrap pa-2" :key="index">
              <SupportMaterialCard
                @open="handleFolderDialog(item.id)"
                @edit="handleFolderForm($event.id)"
                :item="item"
                type="folder"
              />
            </v-col>
          </template>
        </v-row>
        <v-row no-gutters>
          <template v-for="(item, index) in support_materials">
            <v-col cols="2" class="flex-wrap pa-2" :key="index">
              <SupportMaterialCard
                @open="openItem($event.url)"
                @edit="handleMaterialForm($event.id)"
                :item="item"
              />
            </v-col>
          </template>
        </v-row>
      </v-card-text>
    </v-card>
    <SupportMaterialForm ref="SupportMaterialForm" @stored="getTabs" />
    <SupportMaterialTabForm ref="SupportMaterialTabForm" @stored="getTabs" />
    <SupportMaterialFolderForm
      ref="SupportMaterialFolderForm"
      @stored="getTabs"
    />
    <FolderDialog
      @open="openItem($event.url)"
      @edit="handleMaterialForm($event.id)"
      ref="FolderDialog"
    />
  </div>
</template>

<script>
import SupportMaterials from "@/mixins/SupportMaterials/SupportMaterialsMixin";

export default {
  mixins: [SupportMaterials],
};
</script>

<style lang="scss" scoped>
.item-name {
  color: #33303e;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding: 2%;
}
</style>