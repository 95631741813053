<template>
  <v-dialog v-model="dialog" max-width="800px">
    <v-card>
      <v-card-title> {{ title }} </v-card-title>
      <v-card-text>
        <v-row>
          <v-col v-if="!isInsert" class="d-flex justify-center">
            <AppStoreImage
              :uploadUrl="'support-material/admin/image'"
              :media="form.media"
              :params="{ model_id: form.id, model: 'SupportMaterial' }"
              @store="show(form.id)"
            />
          </v-col>
          <v-col cols="12">
            <app-text-field v-model="form.name" label="Nome" />
          </v-col>
          <v-col cols="12">
            <app-text-field v-model="form.url" label="Link" />
          </v-col>

          <v-col cols="6">
            <app-select
              v-model="form.tab_id"
              :items="tabs"
              label="Aba"
              item-text="name"
              item-value="id"
            />
          </v-col>
          <v-col cols="6">
            <app-select
              v-model="form.folder_id"
              :items="folders"
              label="Pasta"
              clearable
              item-text="name"
              item-value="id"
            />
          </v-col>

          <v-col cols="12">
            <app-icon class="mr-4">label</app-icon>
            <span>Etiquetas</span>
            <div class="mt-2">
              <TagsSelect v-model="form.tags" type="support-material" />
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <app-delete-btn v-if="!isInsert" @click="destroy()" />
        <v-spacer />
        <v-btn @click="dialog = false" text> Voltar</v-btn>
        <v-btn @click="save()" color="primary">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TagsSelect from "@/components/app/forms/TagsSelect";
import AppStoreImage from "@/components/app/ui/AppStoreImage";

export default {
  components: {
    TagsSelect,
    AppStoreImage,
  },
  data() {
    return {
      dialog: false,
      form: {},
      baseForm: {
        media: [],
        name: null,
      },
    };
  },

  computed: {
    isInsert() {
      return !this.form.id;
    },

    title() {
      return this.isInsert
        ? "Nova sugestão de estudo"
        : "Editar sugestão de estudo";
    },

    tabs() {
      return this.$store.state.materials.tabs;
    },

    folders() {
      return this.tabs.find((tab) => tab.id == this.form.tab_id)?.folders;
    },
  },

  created() {
    this.reset();
  },

  methods: {
    open(id) {
      this.reset();

      if (id) {
        this.show(id);
      }
      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    show(id) {
      this.$loading.start();
      this.$http
        .show("support-material/admin/material", id)
        .then((response) => {
          this.form = JSON.parse(JSON.stringify(response));
          this.$loading.finish();
        })
        .catch(() => this.$loading.finish());
    },

    save() {
      if (this.isInsert) {
        this.store();
      } else {
        this.update();
      }
    },

    store() {
      this.$loading.start();
      this.$http
        .store("support-material/admin/material", this.form)
        .then(() => this.processSave())
        .catch(() => this.$loading.finish());
    },

    update() {
      this.$loading.start();
      this.$http
        .update("support-material/admin/material", this.form.id, this.form)
        .then(() => this.processSave())
        .catch(() => this.$loading.finish());
    },
    destroy() {
      this.$loading.start();
      this.$http
        .destroy("support-material/admin/material", this.form.id)
        .then((response) => this.processSave())
        .catch(() => this.$loading.finish());
    },

    processSave() {
      this.$emit("stored");
      this.dialog = false;
      this.$loading.finish();
    },
  },
};
</script>

<style>
</style>