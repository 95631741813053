<template>
  <v-dialog v-model="dialog" max-width="350px">
    <v-card>
      <v-card-title> {{ title }} </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <app-text-field v-model="form.name" label="Nome" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <app-delete-btn v-if="!isInsert" @click="destroy()" />
        <v-spacer />

        <v-btn @click="dialog = false" text> Voltar</v-btn>
        <v-btn @click="save()" color="primary">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      form: {},
      baseForm: {
        name: null,
      },
    };
  },

  computed: {
    isInsert() {
      return !this.form.id;
    },
    title() {
      return this.isInsert ? "Nova Aba" : "Editar Aba";
    },
  },

  created() {
    this.reset();
  },

  methods: {
    open(id) {
      this.reset();
      this.dialog = true;
      if (id) {
        this.show(id);
      }
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    show(id) {
      this.$loading.start();
      this.$http
        .show("support-material/admin/tabs", id)
        .then((response) => {
          this.form = JSON.parse(JSON.stringify(response));
          this.$loading.finish();
        })
        .catch(() => this.$loading.finish());
    },

    save() {
      if (this.isInsert) {
        this.store();
      } else {
        this.update();
      }
    },

    store() {
      this.$loading.start();
      this.$http
        .store("support-material/admin/tabs", this.form)
        .then(() => this.processSave())
        .catch(() => this.$loading.finish());
    },

    update() {
      this.$loading.start();
      this.$http
        .update("support-material/admin/tabs", this.form.id, this.form)
        .then(() => this.processSave())
        .catch(() => this.$loading.finish());
    },

    destroy() {
      this.$loading.start();
      this.$http
        .destroy("support-material/admin/tabs", this.form.id)
        .then(() => this.processSave())
        .catch(() => this.$loading.finish());
    },

    processSave() {
      this.$emit("stored");
      this.dialog = false;
      this.$loading.finish();
    },
  },
};
</script>

<style>
</style>