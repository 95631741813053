<template>
  <div>
    <div @click="open()" style="cursor: pointer; font-size: 16px">
      <template v-if="value.length == 0">
        <app-icon class="primary--text">add</app-icon>
        <small class="primary--text">Adicionar Etiquetas</small>
      </template>
      <template v-else>
        <template v-for="tag of value">
          <v-chip
            class="mr-2"
            small
            :key="tag.id"
            :color="tag.color"
            filter
            text-color="white"
            :value="tag.id"
            style="cursor: pointer"
          >
            <v-icon small class="material-symbols-rounded mx-1"> sell </v-icon>
            {{ tag.name }}
          </v-chip>
        </template>
        <app-icon>add</app-icon>
      </template>
    </div>
    <v-dialog v-model="dialog" width="350px">
      <v-card>
        <v-card-title> Adicionar Etiquetas </v-card-title>
        <v-card-text>
          <template v-for="(tag, index) in tags">
            <div
              class="d-flex justify-space-between"
              style="width: 100%"
              :key="index"
            >
              <div style="width: 85%">
                <v-btn
                  class="mb-2"
                  elevation="0"
                  block
                  dark
                  :color="tag.color"
                  @click="toggleTag(tag.id)"
                >
                  <div class="d-flex justify-space-between" style="width: 100%">
                    <div>
                      {{ tag.name }}
                    </div>
                    <div>
                      <v-icon
                        class="material-symbols-rounded"
                        v-if="selectedTagsIds.includes(tag.id)"
                      >
                        done
                      </v-icon>
                    </div>
                  </div>
                </v-btn>
              </div>
              <div style="width: 10%">
                <v-btn icon>
                  <v-icon
                    class="material-symbols-rounded"
                    @click="handleEditTag(tag)"
                  >
                    edit
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </template>
          <div class="d-flex justify-space-between" style="width: 100%">
            <div style="width: 85%">
              <v-btn class="mb-2" text block @click="handleNewTag()">
                Nova Etiqueta
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <app-back-btn @click="dialog = false" />
          <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
          <app-confirm-btn @click="handleSave()" />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <TagForm
      ref="tagForm"
      :type="type"
      @store="loadTags()"
      @delete="tagDeleted($event)"
    />
  </div>
</template>

<script>
import TagForm from "./TagForm";

export default {
  components: { TagForm },

  props: {
    type: {},
    value: {
      default() {
        return [];
      },
    },
  },

  data: () => ({
    dialog: false,

    tags: [],

    selectedTagsIds: [],
  }),

  async created() {
    await this.loadTags();

    this.setSelectedTagsIds();
  },

  watch: {
    value() {
      this.setSelectedTagsIds();
    },
  },

  methods: {
    async loadTags() {
      // this.$store.dispatch("tags/getTags"); // todo duplicated request
      await this.$http
        .index("tag/tag", { type: this.type })
        .then((response) => (this.tags = response));
    },

    setSelectedTagsIds() {
      this.selectedTagsIds = this.value.map((row) => row.id);
    },

    async open() {
      this.dialog = true;
      this.setSelectedTagsIds();
    },

    toggleTag(tagId) {
      if (this.selectedTagsIds.includes(tagId)) {
        this.selectedTagsIds.splice(this.selectedTagsIds.indexOf(tagId), 1);
      } else {
        this.selectedTagsIds.push(tagId);
      }
    },

    async handleSave() {
      this.dialog = false;
      let selectedTags = this.tags.filter((tag) =>
        this.selectedTagsIds.includes(tag.id)
      );
      this.$emit("input", selectedTags);
    },

    handleNewTag() {
      this.$refs.tagForm.open();
    },

    handleEditTag(tag) {
      this.$refs.tagForm.open(tag);
    },

    tagDeleted(id) {
      this.selectedTagsIds.splice(this.selectedTagsIds.indexOf(id), 1);
    },
  },
};
</script>

<style>
</style>