<template>
  <div class="text-end">
    <v-icon v-if="$admin.isAdmin()" @click.stop="$emit('edit', item)">
      mdi-pencil
    </v-icon>
    <v-card
      @click.stop="$emit('open', item)"
      class="pa-2 d-flex flex-column justify-center align-center"
      outlined
    >
      <v-card-text>
        <div v-if="!hasMaterials && type == 'folder'">
          <v-icon>mdi-alert-circle</v-icon>
        </div>
        <div>
          <template v-for="(tag, index) of item.tags">
            <v-chip
              small
              close
              :color="tag.color"
              label
              outlined
              close-icon="mdi-tag"
            >
              {{ tag.name }}
            </v-chip>
            <!-- <v-icon small :color="tag.color" :key="index">mdi-tag</v-icon> -->
          </template>
        </div>
        <v-container>
          <template v-if="!hasImage">
            <div v-if="type == 'folder'" class="text-center">
              <app-icon size="85px">folder</app-icon>
            </div>
            <div v-if="type == 'item'" class="text-center">
              <app-icon size="85px">Image</app-icon>
            </div>
          </template>

          <v-img
            style="height: 70%"
            v-if="hasImage"
            :lazy-src="avatarPath"
            :src="avatarPath"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5" />
              </v-row>
            </template>
          </v-img>
        </v-container>
      </v-card-text>

      <v-card-actions v-if="item.name" class="text-center py-0 mt-auto">
        {{ item.name }}
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import MediaMixin from "@/mixins/Media/MediaMixin";
export default {
  mixins: [MediaMixin],

  props: {
    item: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: "item",
    },
  },
  data() {
    return {};
  },
  computed: {
    mediaData() {
      return this.item.media[0];
    },
    hasImage() {
      return this.mediaData != null;
    },
    avatarPath() {
      return this.getImage(this.mediaData);
    },
    hasMaterials() {
      return this.item.support_materials_count > 0 && this.type == "folder";
    },
  },
};
</script>

<style>
</style>