<template>
  <div>
    <v-alert dark color="info" close>
      <v-row align="center">
        <v-col cols="12" md="10">
          Divulgue seu trabalho no EncontrePsi e seja visto por milhares de
          pessoas em busca de terapia
        </v-col>
        <v-col class="text-right">
          <v-btn @click="openLink()" block elevation="0" color="info">
            Saiba mais!
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <v-tabs>
      <template v-for="item in tabs">
        <v-tab :key="item.id" @click="selectTab(item.id)">
          {{ item.name }}
        </v-tab>
      </template>
    </v-tabs>
    <br />
    <v-row no-gutters>
      <template v-for="(item, index) in folders">
        <v-col cols="6" class="flex-wrap pa-2" :key="index">
          <SupportMaterialCard
            @open="handleFolderDialog(item.id)"
            @edit="handleFolderForm($event.id)"
            :item="item"
            type="folder"
          />
        </v-col>
      </template>
    </v-row>
    <v-row no-gutters>
      <template v-for="(item, index) in support_materials">
        <v-col cols="6" class="flex-wrap pa-2" :key="index">
          <SupportMaterialCard
            @open="openItem($event.url)"
            @edit="handleMaterialForm($event.id)"
            :item="item"
          />
        </v-col>
      </template>
    </v-row>
    <FolderDialog ref="FolderDialog" />
  </div>
</template>

<script>
import SupportMaterials from "@/mixins/SupportMaterials/SupportMaterialsMixin";

export default {
  mixins: [SupportMaterials],
};
</script>

<style lang="scss" scoped>
.item-name {
  color: #33303e;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding: 2%;
}
</style>