import SupportMaterialForm from "@/components/support-materials/forms/SupportMaterialForm.vue";
import SupportMaterialTabForm from "@/components/support-materials/forms/SupportMaterialTabForm.vue";
import SupportMaterialFolderForm from "@/components/support-materials/forms/SupportMaterialFolderForm.vue";
import FolderDialog from "@/components/support-materials/sections/FolderDialog.vue";
import SupportMaterialCard from "@/components/support-materials/sections/SupportMaterialCard.vue";
export default {
  components: {
    SupportMaterialForm,
    SupportMaterialTabForm,
    SupportMaterialFolderForm,
    SupportMaterialCard,
    FolderDialog,
  },
  data() {
    return {};
  },

  created() {
    this.init();
  },

  computed: {
    folders() {
      return this.$store.getters["materials/folders"];
    },
    support_materials() {
      return this.$store.getters["materials/support_materials"];
    },
    tabs() {
      return this.$store.state.materials.tabs;
    },

    query: {
      get() {
        return this.$store.state.materials.query;
      },
      set(value) {
        this.$store.commit("materials/setQuery", value);
      },
    },
  },

  methods: {
    async init() {
      await this.getTabs();
      var tab = this.tabs[0];
      this.query.tabId = tab?.id;
    },

    openItem(url) {
      window.open(url, "_blank");
    },

    openLink() {
      window.open("https://www.encontrepsi.com.br/", "_blank");
    },

    selectTab(id) {
      this.query.tabId = id;
      this.getTabs();
    },

    async getTabs() {
      this.$loading.start();
      await this.$store
        .dispatch("materials/getTabs")
        .then(() => this.$loading.finish())
        .catch(() => this.$loading.finish());
    },

    handleMaterialForm(id) {
      this.$refs.SupportMaterialForm.open(id);
    },

    handleTabForm(id) {
      this.$refs.SupportMaterialTabForm.open(id);
    },

    handleFolderForm(id) {
      this.$refs.SupportMaterialFolderForm.open(id);
    },

    handleFolderDialog(id) {
      this.$refs.FolderDialog.open(id);
    },
  },
};
