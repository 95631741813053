<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    scrollable
    max-width="900px"
  >
    <v-card>
      <v-card-title primary-title> {{ folder.name }} </v-card-title>

      <v-card-text class="pa-2">
        <v-row no-gutters>
          <template v-for="(item, index) in support_materials">
            <v-col cols="6" md="3" class="flex-wrap pa-2" :key="index">
              <SupportMaterialCard
                @open="openItem($event.url)"
                :item="item"
                v-on="$listeners"
              />
            </v-col>
          </template>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="dialog = false" text>Voltar</v-btn>
      </v-card-actions>
    </v-card>
    <SupportMaterialForm
      ref="SupportMaterialForm"
      @stored="getSupportMaterials"
    />
  </v-dialog>
</template>

<script>
import SupportMaterialCard from "@/components/support-materials/sections/SupportMaterialCard.vue";
import SupportMaterialForm from "@/components/support-materials/forms/SupportMaterialForm.vue";

export default {
  components: {
    SupportMaterialCard,
    SupportMaterialForm,
  },
  data() {
    return {
      dialog: false,

      folder: {
        support_materials: [],
        tags: [],
      },
    };
  },

  computed: {
    support_materials() {
      return this.folder.support_materials;
    },
  },

  methods: {
    async open(folderId) {
      this.$loading.start();
      this.$http
        .index("support-material/folders", { folderId: folderId })
        .then((response) => {
          this.folder = JSON.parse(JSON.stringify(response.folder));
          this.$loading.finish();
          this.dialog = true;
        })
        .catch((error) => this.$loading.finish());
    },

    getSupportMaterials(folderId, tabId) {
      this.$http
        .index("support-material/materials", {
          folderId: folderId,
          tabId: tabId,
        })
        .then((response) => {
          this.support_materials = response.support_materials;
          this.dialog = true;
        });
    },
    handleMaterialForm(id) {
      this.$refs.SupportMaterialForm.open(id);
    },
    openItem(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style>
</style>